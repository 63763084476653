import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTruck, faWrench, faPlug } from '@fortawesome/free-solid-svg-icons'

const Services = () => <section id="services">
    <div className="max-w-screen-xl mx-auto">
        <h2>Szolgáltatásaink</h2>
        <p>Kamionja ponyvája felett eljárt az idő, és úgy gondolja újra van szüksége? Esetleg a régit próbálná meg javíttatni?</p>
        <p>Sátorgarázshoz szeretne fémvázat vagy bármilyen más fémszerkezet tervezésében, kivitelezésében szorul segítségre?</p>
        <p>Jó helyen jár! Szolgáltatásaink listájából kiderül, hogy miben is segíthetünk Önnek!</p>

        <div className="mt-5 grid grid-cols-1 lg:grid-cols-3 lg:pt-14">
            <div className="service-card mx-2">
                <div className="text-primary-500">
                    <FontAwesomeIcon icon={faTruck} />
                </div>
                <h4>Ponyva megmunkálás</h4>
                <p>Vállaljuk ponyvák elkészítését különböző felhasználási területekre (kamion ponyva, sátorgarázs, terasz borítás stb.), illetve elöregedett, de még felújítható ponyvák felélesztését.</p>
            </div>
            <div className="service-card mx-2">
                <div className="text-primary-500">
                    <FontAwesomeIcon icon={faWrench} />
                </div>
                <h4>Fém megmunkálás</h4>
                <p>Különböző fémmunkák teljesítését vállaljuk egészen a tervezés kezdő lépésétől, a kivitelezésen át a végtermék használatra kész összeszereléséig.</p>
            </div>
            <div className="service-card mx-2">
                <div className="text-primary-500">
                    <FontAwesomeIcon icon={faPlug} />
                </div>
                <h4>Műszaki megoldások</h4>
                <p>Illetve különböző műsszaki jellegű megbízásokat teljesítünk ügyfeleink kérésére az adott munka előzetes felmérését követően.</p>
            </div>
        </div>
    </div>
</section>

export default Services;