const Hero = () => <section id="hero" className="p-0 relative">
    <div className="py-16 px-4 lg:py-32 mx-auto text-center">
        <p id="headline" className="mb-4 text-4xl font-extrabold tracking-tight leading-loose text-secondary-600 ">Elhivatottság, <span className="text-primary-600 underline">szakértelem,</span> minőség!</p>
        <div className="flex flex-col mt-16 mb-8 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
            <a href="#about-us" className="inline-flex justify-center items-center py-3 px-5 text-base font-bold text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300">
                Rólunk
            </a>
            <a href="#gallery" className="inline-flex justify-center items-center py-3 px-5 text-base font-bold text-center text-white rounded-lg bg-secondary-700 hover:bg-secondary-800 focus:ring-4 focus:ring-secondary-300">
                Képek a múltból
            </a>
        </div>
    </div>
</section>

export default Hero