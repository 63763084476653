import { useState } from "react"

const Nav = () => {
    let [menuOpened, setMenuOpened] = useState(false)

    const toggleOpened = () => {
        setMenuOpened(!menuOpened)
    }

    return (<nav className="bg-white border-b border-gray-200">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
            <a href="#hero" id="logo" className="flex items-center">
                <span className="self-center font-semibold whitespace-nowrap text-secondary-500">Nyír<span className="text-primary-500">Ponyva</span></span>
            </a>
            <button onClick={toggleOpened} data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" aria-controls="navbar-default" aria-expanded={menuOpened}>
                <span className="sr-only">Open main menu</span>
                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
                </svg>
            </button>
            <div className={`${menuOpened ? '' : 'hidden '}w-full lg:block md:w-auto md:mx-auto md:mt-3 lg:mx-0 lg:mt-0`} id="navbar-default">
                <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white md:mx-auto">
                    <li>
                        <a href="#about-us" onClick={toggleOpened} className="block py-2 pl-3 pr-4 text-gray-900 rounded md:bg-transparent md:hover:text-blue-700 md:p-0" aria-current="page">A Nyírponyváról</a>
                    </li>
                    <li>
                        <a href="#services" onClick={toggleOpened} className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0">Szolgáltatásaink</a>
                    </li>
                    <li>
                        <a href="#gallery" onClick={toggleOpened} className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0">Képek a múltból</a>
                    </li>
                    <li>
                        <a href="#contact-us" onClick={toggleOpened} className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0">Elérhetőségek</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>)
}

export default Nav