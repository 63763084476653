import { useState } from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const breakpoints = [1600, 1080, 640, 384];

const photoLink = (id, width) => `${process.env.PUBLIC_URL}/portfolio/${id}_${width}.jpg`;

const photos = [
    {
        id: 'cabrio',
        height: 1200
    },
    {
        id: 'pool',
        height: 2133
    },
    {
        id: 'tent',
        height: 1200
    },
    {
        id: 'tent2',
        height: 2133
    },
    {
        id: 'tent3',
        height: 1200
    },
    {
        id: 'truck',
        height: 1200
    }
]

const slides = photos.map((photo) => {
    const width = breakpoints[0]
    const height = photo.height

    return {
        src: photoLink(photo.id, width),
        width,
        height,
        srcSet: breakpoints.map((breakpoint) => {
            const _height = Math.round((height / width) * breakpoint)
            return {
                src: photoLink(photo.id, breakpoint),
                width: breakpoint,
                height: _height
            }
        })
    }
})

const Gallery = () => {
    const [index, setIndex] = useState(-1);

    return (<section id="gallery">
        <div className="max-w-screen-xl mx-auto">
            <h2>Képek a múltból</h2>
            <p>Felkeltettük érdeklődését, de még nem tudott dönteni? Tekintse meg korábbi munkáinkat portfóliónkban, hiszen egy kép többet ér ezer szónál!</p>
        </div>

        <div className="max-w-screen-xl mx-auto lg:pt-14">
            <PhotoAlbum photos={slides} layout="rows" targetRowHeight={150} onClick={({ index }) => setIndex(index)} />

            <Lightbox
                slides={slides}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                // enable optional lightbox plugins
                plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
            />
        </div>
    </section>)
}

export default Gallery;