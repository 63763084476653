import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'

const ContactBar = () => <section id="contactBar" className="border-b p-0">
    <div className="flex p-2 sm:px-4 max-w-screen-xl mx-auto">
        <div className="w-1/2">
            <a href="mailto:info@nyirponyva.hu" className='hover:text-primary-500'><span className='text-primary-500'><FontAwesomeIcon icon={faEnvelope} /></span>&nbsp;info@nyirponyva.hu</a>
        </div>
        <div className="w-1/2 flex justify-end">
            <a href="tel:+36703354283" className='hover:text-primary-500'><span className='text-primary-500'><FontAwesomeIcon icon={faPhone} /></span>&nbsp;+36&nbsp;70&nbsp;335&nbsp;4283</a>
        </div>
    </div>
</section>

export default ContactBar;
