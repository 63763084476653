const AboutUs = () => <section id="about-us">
    <div className="max-w-screen-xl mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2">
            <div className="hidden lg:block lg:pr-4 pt-16">
                <img src="/about-us.jpg" alt="" />
            </div>
            <div className="lg:pl-4">
                <h2>Ismerjen meg minket</h2>

                <p className="italic text-xl font-light mb-6">Több, mint két évtizedes szakmai tapasztalat teszi a Nyír Ponyvát a legjobb választássá.</p>
                <p>1994 óta foglalkozunk ponyvák különböző felhasználási területre történő tervezésével, elkészítésével, felszerelésével. A ponyvázáson túl különböző fém és műszaki munkák elvégzésében lehetünk ügyfeleink segítségére.</p>
                <p>Elsősorban az ország keleti régiójában végzünk tevékenységet, azon belül is Szabolcs-Szatmár-Bereg megyében.</p>
                <p>Legyen szó ponyváról kamionra, üvegfóliáról teraszra, erkélyre téliesítéssel, fémszerkezetek tervezéséről és kivitelezéséről, a Nyír Ponyva kézséggel áll szolgálatára.</p>
            </div>
        </div>

    </div>
</section>

export default AboutUs;
