const ContactUs = () => <section id="contact-us">
    <div className="max-w-screen-xl mx-auto">
        <h2>Elérhetőségek</h2>
        <p>Bármilyen kérdése támadt, vagy csak árajánlatot szeretne kérni egy munkával kapcsolatban, a megadott elérhetőségeim bármelyikén megtalál!</p>
    </div>

    <div id="contact-cards" className="max-w-screen-xl mx-auto mt-5 grid grid-cols-1 md:grid-cols-3 lg:pt-14">
        <div>
            <h4>Cím</h4>
            <p>4400 Nyíregyháza, Kagyló utca</p>
        </div>

        <div>
            <h4>Telefonszám</h4>
            <p><a href="tel:+36703354283">+36&nbsp;70&nbsp;335&nbsp;4283</a></p>
        </div>

        <div>
            <h4>Email</h4>
            <p><a href="mailto:info@nyirponyva.hu">info@nyirponyva.hu</a></p>
        </div>
    </div>
</section>

export default ContactUs;