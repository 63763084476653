import './App.scss';
import AboutUs from './components/about-us';
import ContactBar from './components/contact-bar';
import ContactCta from './components/contact-cta';
import ContactUs from './components/contact-us';
import Footer from './components/footer';
import Gallery from './components/gallery';
import Hero from './components/hero';
import Nav from './components/nav';
import Services from './components/services';

const App = () => <>
  <div className="fixed w-full bg-white z-10">
    <ContactBar />
    <Nav />
  </div>
  <div className='pt-28 lg:pt-24'>
    <Hero />
  </div>
  <AboutUs />
  <Services />
  <ContactCta />
  <Gallery />
  <ContactUs />
  <Footer />
</>

export default App;
